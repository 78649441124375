// ----------------------------------------------------------------------

import { faker } from "@faker-js/faker";

/**
 * 
 * @type {{ 
* address: string
* bearerToken: string
* createdAt: string
* updatedAt: string
* email: string
* firstName: string
* lastName: string
* _id: string
* lastDashboardAccess: string
* phoneNumber: string
* userVerified: true
* }[]}
*/
export const accounts = [{ 
  address: faker.location.streetAddress(),
  bearerToken: faker.string.nanoid(128),
  email: 'test@demo.com',
  firstName:'Michael',
  lastName: 'Piper',
  password: 'password',
  phoneNumber: '09078695665',
  _id: '4136cd0b-d90b-4af7-b485-5d1ded8db252',
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.past().toISOString(),
  lastDashboardAccess: faker.date.past().toISOString(),
  photoURL: '/assets/images/avatars/avatar_25.jpg',
  userVerified: true,
  offerings:null
}];
