import PropTypes from 'prop-types'
import { useMemo, useState, useEffect } from 'react';

import { useRouter } from 'src/routes/hooks';

import routes from 'src/utils/routes';

import { AuthContext } from './auth-context';
import { getAuthData } from './services/auth';
// ----------------------------------------------------------------------
export function AuthorizationProvider ({ children })  { 
    const [account,setAccount] = useState(getAuthData());
    const router = useRouter();
    useEffect(()=>{
      localStorage.setItem('auth', JSON.stringify(account));
    }, [account])
    const providerValue = useMemo(()=>({ 
      account, 
      setAccount,
     
      logout(){
        setAccount(null)
        router.replace(routes.LoginPage)
        // eslint-disable-next-line no-plusplus
        for(let i =0 ; i< localStorage.length; i++){
          const key = localStorage.key(i)
          if(key.startsWith('auth')){
            localStorage.removeItem(key)
          }
        }
      }
     }),[account, router])
    return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  )
}

AuthorizationProvider.propTypes = {
  children: PropTypes.node,
};