const routes = {
    ActivateAccountPage:'/activate-account',
    ChooseOfferingsPage: '/choose-offerings',
    LoginPage:'/login',
    ProfilePage: '/profile',
    ProfileEditPage: '/profile/edit',
    ProfileChangePasswordPage: '/profile/change-password',
    ProfileSettingsPage: '/profile/settings',
    HomePage:'/',
    WalletFundingPage: '/wallet/funding',
    CreateRegistryRecordPage:'/registry/new-record',
    RegistryPage:'/registry'
}
export default routes