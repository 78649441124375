/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import { AuthorizationProvider } from 'src/auth/authorization-provider';
import { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackBarProvider } from './components/snackbar/snackbar-provider';
import { boot } from './boot'
// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();
  useEffect(()=>{
    boot().then(console.log)
  },[])
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ThemeProvider>
      <SnackBarProvider>
      <AuthorizationProvider>
        <Router />
      </AuthorizationProvider>
      </SnackBarProvider>
    </ThemeProvider>
    </LocalizationProvider>
  );
}
