import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRoute() {
const navigation = useLocation();
const route = useMemo(
  () => ({
    state: ()=> navigation.state
  }),
  [navigation]
);

return route;
}

export function useRouter() {
  const navigate = useNavigate();
 
  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href, state) => navigate(href, {state}),
      replace: (href, state) => navigate(href, {state, replace: true }),
    }),
    [navigate]
  );

  return router;
}
