
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import routes from 'src/utils/routes';

import { useAuthenticate } from "src/auth/hooks/use-authenticate";
import { Box } from '@mui/material';


export function LoginGuard({ children, use}) {
  const account = useAuthenticate();
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    if( typeof use === "function" && (!account || !account.userVerified) && !use(account)) {
      // if(!account.userVerified ){
      //   navigate(routes.ActivateAccountPage);
      // }else{
        navigate(routes.LoginPage);
      // }
    }
    else if(!account ||  !account.userVerified) {
      // if(!account.userVerified ){
      //   navigate(routes.ActivateAccountPage);
      // }else{
       navigate(routes.LoginPage);
      // }
    }else{
      setAllowed(true)
    }
  }, [account, navigate, use]);
  
  if(!allowed){
    return <Box/>
  }
  return children
}
LoginGuard.propTypes = {
    children: PropTypes.element,
    use: PropTypes.func,
}; 