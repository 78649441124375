import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { LoginGuard } from 'src/auth/guards';
import DashboardLayout from 'src/layouts/dashboard';

import routes from '../utils/routes'

export const IndexPage = lazy(() => import('src/pages/app'));
export const BlogPage = lazy(() => import('src/pages/blog'));
export const UserPage = lazy(() => import('src/pages/user'));
export const LoginPage = lazy(() => import('src/pages/login'));
export const RegisterPage = lazy(() => import('src/pages/register'));

export const ProfilePage = lazy(() => import('src/pages/profile'));
export const ProfileSettingsPage = lazy(() => import('src/pages/profile-settings'));
export const ProfileEditPage = lazy(() => import('src/pages/profile-edit'));
export const ProfileChangePasswordPage = lazy(() => import('src/pages/profile-change-password'));
export const WalletFundingPage = lazy(() => import('src/pages/wallet-funding'));
export const RegistryPage = lazy(() => import('src/pages/registry'));


export const ActivateAccountPage = lazy(() => import('src/pages/activate-account'));
export const ChooseOfferingsPage = lazy(() => import('src/pages/choose-offerings'));
export const CreateRegistryRecordPage = lazy(() => import('src/pages/create-registry-record'));


export const ProductsPage = lazy(() => import('src/pages/products'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const Routes = useRoutes([
    {
      element: (
        <LoginGuard>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
        </LoginGuard>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'profile/settings', element: <ProfileSettingsPage /> },
        { path: 'profile/edit', element: <ProfileEditPage /> },
        { path: 'profile/change-password', element: <ProfileChangePasswordPage /> },
        { path: 'wallet/funding', element: <WalletFundingPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'registry', element: <RegistryPage /> },
        { path: routes.CreateRegistryRecordPage, element: <CreateRegistryRecordPage /> },

        
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: routes.ActivateAccountPage,
      element: <ActivateAccountPage />,
    },
    {
      path: routes.ChooseOfferingsPage,
      element: <ChooseOfferingsPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return Routes;
}
