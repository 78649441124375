import { faker } from '@faker-js/faker'
import axios, { AxiosError } from 'axios'
import { accounts } from 'src/_mock/account'
import {userdb} from  'src/config'
/**
 * @type {import('axios').Axios&{
 * action: (action:string, payload:any, options?: any)=> Promise<import('axios').AxiosResponse>
 * }}
 */
export const userHttpClient = axios.create({
    baseURL: import.meta.env.VITE_userModule_BaseURL
})
userHttpClient.interceptors.request.use((req) =>{
    if(!req.headers?.Authorization){
        const username = import.meta.env.VITE_userModule_AuthUser
        const password = import.meta.env.VITE_userModule_AuthPass
        
        req.auth = {username, password}
    }
    return req
})

userHttpClient.action = async(action, payload, options = {})=>{
  switch(action){
    case '/user/authenticate':{
        if(!payload.email){
            throw new AxiosError()
        }
        let account = await userdb.find({
            selector:{
                email: payload.email,
                password: payload.password,
            }
          
        }).then((el)=>el.docs.pop())
        if( !account){
          account = accounts.find((_account)=>_account.email ===  payload.email && _account.password === payload.password)
          if(account){ 
            await  userdb.post(account)
          }
        }
        if(!account){
            throw new AxiosError('NotAuthorized', 401, null,null, {data: {message: 'Invalid credentials'}})
        }
        
        const result = {
            data: {data:account, message: 'Login successfully'},
            status: 200,
            statusText: 'Ok',
        }
        return result
    }
    case '/user/offerings':{
        if(!payload.email){
            throw new AxiosError('BadRequest', 400, null, null, {
                data:{
                    message:"email is required"
                }
            })
        }
        if(!payload.offerings || typeof payload.offerings !=='object'){
            throw new AxiosError('BadRequest', 400, null, null, {
                data:{
                    message:"offerings property is required"
                }
            })
        }
        const account = await userdb.find({
            selector:{
                email: payload.email,
            }
          
        }).then((el)=>el.docs.pop())
        if(!account){
            throw new AxiosError('NotAuthorized', 401, null,null, {data: {message: 'Invalid credentials'}})
        }
        account.offerings = payload.offerings
        await userdb.put({...account})
        
        const result = {
            data: {data:account, message: 'offerings updated successfully'},
            status: 200,
            statusText: 'Ok',
        }
        return result
    }
    case '/user/register':{
        if(!payload.email){
            throw new AxiosError()
        }
        let account = await userdb.find({
            selector:{
                email: payload.email,
            }
          
        }).then((el)=>el.docs.pop())
        if(account){
            throw new AxiosError('BadRequest', 400, null, null, {
                data:{
                    message:"email already registered"
                }
            })
        }
        const {id} = await userdb.post({
            ...payload, 
            _id: faker.string.uuid(),
            createdAt: faker.date.past().toISOString(),
            updatedAt: faker.date.past().toISOString(),
            lastDashboardAccess: faker.date.past().toISOString(),
            userVerified: false
        })
        account = await userdb.get(id)
        const result = {
            data: { data: account, message: 'Registration successfully'},
            status: 200,
            statusText: 'Ok',
        }
        return result
    }

    case '/user/completeRegistration':{
        if(!payload.email || !payload.verificationCode ){
            throw new AxiosError('BadRequest', 400, null, null, {
                data:{
                    message:"verificationCode and email required"
                }
            })
        }
        const account = await userdb.find({
            selector:{
                email: payload.email,
            }
          
        }).then((el)=>el.docs.pop())
        if(!account){
            throw new AxiosError('NotAuthorized', 401, null,null, {data: {message: 'Invalid credentials'}})
        }
        
        if(!['000000', '123456'].includes(payload.verificationCode )){
            throw new AxiosError('NotAuthorized', 401, null,null, {data: {message: 'Invalid OTP code'}})
        }
        await userdb.put({...account, userVerified: true})
        account.userVerified = true
        const result = {
            data: { data:account, message: 'Email verified successfully'},
            status: 200,
            statusText: 'Ok',
        }
        return result
    }

    case '/services/decryptUserToken':{
        if(!payload.token){
            throw new AxiosError('BadRequest', 400, null, null, {
                data:{
                    message:"Token required"
                }
            })
        }
        const account = accounts.find((_account)=>_account.email ===  payload.email && _account.password === payload.password)
        if(!account){
            throw new AxiosError('NotAuthorized', 401, null,null, {data: {message: 'Invalid credentials'}})
        }
        const result = {
            data: {data:{decryptedToken: payload.token}, message: 'Token decrypted successfully'},
            status: 200,
            statusText: 'Ok',
        }
        return result
    }
    default: 
    throw new AxiosError('NotFound', 404, null,null, {data: {message: 'Action not found'}})
  }
}
