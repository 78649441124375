import PropTypes from 'prop-types'
import { useMemo, useState, useContext } from 'react';

import Snackbar from '@mui/material/Snackbar';

import {shortid} from "../../utils/shortid"
import { SnackBarContext } from './snackbar-context';
// ----------------------------------------------------------------------


export function SnackBars(){
  const {snackbar} = useContext(SnackBarContext)
  return (
    snackbar.map((snackbarItem)=>snackbarItem.view)
  )
}
export function SnackBarProvider ({ children, show })  {
    const [snackbar,setSnackbar] = useState([]);
    show ??=true
    const providerValue = useMemo(()=>({ 
      snackbar, 
      setSnackbar,
      open(view, id){
        const snack = {view,id:id??shortid(), open: true};
        setSnackbar((_snackbar)=>[..._snackbar, snack]
      );
        return snack;
      },
      close(item){
        setSnackbar((_snackbar)=>{
          const index =_snackbar.findIndex((searchItem=>searchItem.id===item?.id?? item))
          if(index>-1){
            _snackbar.splice(index, 1);
          }
          return [..._snackbar]
        })
      }
     }),[snackbar])
    return (
    <SnackBarContext.Provider value={providerValue}>
      {children}
     {show && snackbar.map((snackbarItem)=> (  <Snackbar key={snackbarItem.id} open autoHideDuration={6000} onClose={()=>providerValue.close(snackbarItem)}>
{snackbarItem.view}
     </Snackbar>))}
    </SnackBarContext.Provider>
  )
}

SnackBarProvider.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
};