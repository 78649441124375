import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name, ext='svg') => (
  <SvgColor src={`/assets/icons/navbar/${name}.${ext}`} sx={{ width: 1, height: 1 }} />
);
const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'registry',
    path: '/registry',
    icon: icon('ic_user'),
  },
];

export default navConfig;
