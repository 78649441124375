import axios from "axios"

import { Result } from "src/utils/result"

import { userHttpClient } from "src/http-clients/user-http-client"



export const login = async  (username, password) => {
    try {
        const result = await userHttpClient.action('/user/authenticate', {
            "email": username,
            "password": password
          })    
        return Result.ok(result.data,  Result.getMessage(result.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
 
}

export const register = async  ({lastName,firstName,phoneNumber,email,address, password}) => {
    try {
        const result = await userHttpClient.action('/user/register', {
            "firstName": firstName,
            "lastName": lastName,
            "email":email,
            "password": password,
            "phoneNumber": phoneNumber,
            "address": address
          })    
        return Result.ok(result.data,  Result.getMessage(result.data, "Registration successful"))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data, 'Registration failed'))
        }
        return Result.error(error, Result.getMessage(error, 'Registration failed due to unexpected issue.'))
    }
 
}


export const updateOfferings = async  ({email,offerings}) => {
    try {
        const result = await userHttpClient.action('/user/offerings', {
            "email":email,
            "offerings": offerings,
          })    
        return Result.ok(result.data,  Result.getMessage(result.data, "Registration successful"))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data, 'Registration failed'))
        }
        return Result.error(error, Result.getMessage(error, 'Registration failed due to unexpected issue.'))
    }
 
}
export const completeRegistration = async  (email, verificationCode) => {
    try {
        const result = await userHttpClient.action('/user/completeRegistration', {
            "email": email,
            "verificationCode": verificationCode
          })    
        return Result.ok(result.data,  Result.getMessage(result.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

export const retriggerVerificationCode = async  (email) => {
    try {
        const result = await userHttpClient.action('/user/retriggerVerificationCode', {
            "email": email,
          })    
        return Result.ok(result.data,  Result.getMessage(result.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}


export const decryptUserToken = async  (token) => {
    try {
        const result = await userHttpClient.action('/services/decryptUserToken', {
            "token": token,
        })    
        return Result.ok(result.data,  Result.getMessage(result.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}


export const verifyUserToken = async  ({email, token}) => {
    try {
        const result = await userHttpClient.post('/services/verifyUserToken', {
            "email": email,
            "token": token,
        })    
        return Result.ok(result.data,  Result.getMessage(result.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}

/**
 * 
 * @returns {{ 
* getFullName:()=>string
* address: string
* bearerToken: string
* createdAt: string
* updatedAt: string
* email: string
* firstName: string
* lastName: string
* id: string
* lastDashboardAccess: string
* phoneNumber: string
* userVerified: true
* }| null}
*/
export const getAuthData =()=>{
    try {
        const storeString = localStorage.getItem('auth')?? null
        const store = storeString? JSON.parse(storeString): null
        return store  
    } catch (error) {
       return null 
    }
}

export const getEncryptedToken =()=>getAuthData()?.bearerToken ?? null
export const getDecryptedToken=()=>localStorage.getItem('auth:token:decrypted') ?? null
export const setDecryptedToken=(token)=>{
    localStorage.setItem('auth:token:decrypted', token)
}

export const getAuthToken = async () => {
    const storeToken = getDecryptedToken()
    if (storeToken) {
        console.log('storeToken' , storeToken)
        return Result.ok({data:storeToken})
    }
    const encryptedToken = getEncryptedToken()
    const result = await decryptUserToken(encryptedToken)
    if(result.notOk){
        return Result.error(null, result.message??'Invalid Auth Data')
    }
    if(!result.has('data.decryptedToken')){
        return Result.error(null, 'decrypt data doesn\'t contain property data.decryptedToken')
    }
    const decryptedToken = result.get('data.decryptedToken', null)
    setDecryptedToken(decryptedToken)
    return Result.ok({data:decryptedToken})
}


export const resetPassword = async  (currentPassword, newPassword) => {
    const account = getAuthData()
    const { email } = account
    const authToken = await getAuthToken()
    try {
        const result = await userHttpClient.put('/user/resetPassword', {
            "email": email,
        "currentPassword": currentPassword,
        "newPassword": newPassword
          }, {
            headers:{
                'Authorization': `Bearer ${authToken.data}`
            }
          })    
        return Result.ok(result.data,  Result.getMessage(result.data))
    } catch (error) {
        if(axios.isAxiosError(error)){
            return Result.error(error.response.data, Result.getMessage(error.response.data))
        }
        return Result.error(error, Result.getMessage(error))
    }
}