import { useContext, useMemo } from 'react';

import { AuthContext } from '../auth-context';
// ----------------------------------------------------------------------
/**
 * 
 * @returns {{ 
 * getFullName:()=>string
 * address: string
 * bearerToken: string
 * createdAt: string
 * updatedAt: string
 * email: string
 * firstName: string
 * lastName: string
 * id: string
 * _id?: string
 * _rev?: string
 * lastDashboardAccess: string
 * phoneNumber: string
 * userVerified: true
 * offerings: Record<string, any>
 * }}
 */
export function useAuthenticate() {
  const { account } = useAuthorization();
  return account
}

/**
 * 
 * @returns {{ 
 * account:{
* getFullName:()=>string
* address: string
* bearerToken: string
* createdAt: string
* updatedAt: string
* email: string
* firstName: string
* lastName: string
* id: string
* _id?: string
* _rev?: string
* lastDashboardAccess: string
* phoneNumber: string
* userVerified: true
* offerings: Record<string, any>
* }}}
*/
export const useAuthorization = () => {
  const ctx = useContext(AuthContext);
  
  const account = useMemo(() => (ctx.account?{
    ...ctx.account,
    get id() {
      return this._id;
    },
    getFullName() {
      return ([ctx.account.firstName, ctx.account.lastName]).join(' ')
    }
  }: ctx.account), [ctx]);
  return { ...ctx, account };
}